.painel-lista-botoes {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.MuiCardActions-root .btn-excluir-icone {
  padding: 8px;
  font-size: 1.2rem;
}

.backdrop {
	position: fixed;
	display: flex;
	justify-content: space-around;
	align-items: center;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0, 0, 0, 0.55);
	z-index: 50;
}

.MuiCard-root {
  .topo {
    display: flex;
    height: 42px;
    background-color: #008591;
  }
  .titulo {
    padding: 6px 12px;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .titulo > p {
    color: rgba(255, 255, 255, 0.5) !important;
    display: flex;
    align-items: center;
  }

  .acoes {
    padding: 6px;
    color: rgba(255, 255, 255, 0.5) !important;
  }
}

@import "backdrop";
@import "modal";
@import "flex";
@import "uteis";
@import "botoes";
@import "app";
@import "tabelas";
@import "menu";
@import "painel-lista";
@import "card";
@import "topo";
@import "temas";

#root,
.app,
body,
html {
  min-height: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  text-decoration: none;
}

.link-color {
  color: inherit;
}

.espacamentoTopo {
  height: 20px;
}

.adicionaRemoveQuantidade-input {
  text-align: center !important;
  width: 40px !important;
  background-color: #ccc;
  border: none;
}

.adicionaRemoveQuantidade-input > input {
  text-align: center !important;
  border: none;
}

// @media (min-width: 640px) {
//   body {
//     background-color: #ffcc00 !important;
//   }
// }

// @media (min-width: 768px) {
//   body {
//     background-color: #2dbb1b !important;
//   }
// }

// @media (min-width: 1024px) {
//   body {
//     background-color: #1ecbd1 !important;
//   }
// }

// @media (min-width: 1200px) {
//   body {
//     background-color: #e21ee2 !important;
//   }
// }

.backdrop {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 50;
}

.modal {
  max-width: 90%;
  max-height: 90%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__header {
  padding: 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}

.modal__header h1 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 2.2rem;
  flex-grow: 1;
}

.modal__actions {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.modal-sm {
  width: 30rem;
}

.modal-md {
  width: 50rem;
}

.modal-lg {
  width: 90%;
}

.modal-full {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.modal-full > .modal__content {
  overflow: auto;
  height: 100%;
}

.modal__content {
  position: absolute;
  top: 68px;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline;
}

.j-around {
  justify-content: space-around;
}

.j-between {
  justify-content: space-between;
}

.j-center {
  justify-content: center;
}

.j-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.text-withe {
  color: #fff;
}

.m-0 {
  margin: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.ml-7 {
  margin-left: 1.75rem !important;
}

.mr-7 {
  margin-right: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.ml-9 {
  margin-left: 2.25rem !important;
}

.mr-9 {
  margin-right: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.h-100 {
  height: 100%;
  min-height: 100%;
}

.width-50 {
  width: 50px !important;
}

.width-100 {
  width: 100px !important;
}

.width-150 {
  width: 150px !important;
}

.width-200 {
  width: 200px !important;
}

.width-250 {
  width: 250px !important;
}

.width-300 {
  width: 300px !important;
}

.width-350 {
  width: 350px !important;
}

.width-400 {
  width: 400px !important;
}

.width-450 {
  width: 450px !important;
}

.width-500 {
  width: 500px !important;
}

.bg-white {
  background-color: white !important;
}

.line-height-1 {
  line-height: 1;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.btn-warning {
  background-color: #ffcc00 !important;
}

.btn-warning:hover {
  background-color: #dab008 !important;
}

.btn-success {
  background-color: #6bdd0e !important;
}

.btn-success:hover {
  background-color: #5bb90e !important;
}

.btn-danger {
  background-color: #d34040 !important;
}

.btn-danger:hover {
  background-color: #a33232 !important;
}

.btn-dark {
  background-color: #2c2c2c !important;
  color: #fff !important;
}

.btn-dark:hover {
  background-color: black !important;
  color: #fff !important;
}

.MuiCardHeader-action {
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.MuiTableCell-head {
  opacity: 0.5;
}

.link-tabela {
  display: flex;
  width: 100%;
}

.table-header {
  padding: 12px 12px !important;
}

.table-body {
  padding: 6px 12px !important;
  display: flex;
}

.tabela-linha-hover:hover {
  background-color: #b4470a;
  transition: background-color ease-in-out 0.3s;
}

.tabela-linha-hover:hover > td > a {
  transition: color ease-in-out 0.3s;
  color: #fff;
}

.menu-principal-ativo > div {
  background-color: #217984 !important;
  color: white !important;
}

.menu-principal > div {
  color: rgba(255, 255, 255, 0.6);
}

.sub-menu-aberto > div {
  color: white !important;
}

.painel-lista-botoes {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.MuiCardActions-root .btn-excluir-icone {
  padding: 8px;
  font-size: 1.2rem;
}

.MuiCard-root .topo {
  display: flex;
  height: 42px;
  background-color: #008591;
}
.MuiCard-root .titulo {
  padding: 6px 12px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.MuiCard-root .titulo > p {
  color: rgba(255, 255, 255, 0.5) !important;
  display: flex;
  align-items: center;
}
.MuiCard-root .acoes {
  padding: 6px;
  color: rgba(255, 255, 255, 0.5) !important;
}

#topo {
  background-color: #008591;
  display: flex;
  width: 100%;
  top: 0;
  z-index: 999;
}
#topo .logo {
  padding: 6px;
  display: flex;
  flex-direction: column;
  width: 110px;
}
#topo .logo img {
  display: block;
}
#topo .logo .nomeAplicacao {
  color: #fff;
  font-size: 0.6rem;
  text-align: right;
}

.tema-claro,
.tema-escuro {
  min-height: 100%;
}

#root,
.app,
body,
html {
  min-height: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  text-decoration: none;
}

.link-color {
  color: inherit;
}

.espacamentoTopo {
  height: 20px;
}

.adicionaRemoveQuantidade-input {
  text-align: center !important;
  width: 40px !important;
  background-color: #ccc;
  border: none;
}

.adicionaRemoveQuantidade-input > input {
  text-align: center !important;
  border: none;
}


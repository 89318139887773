.link-tabela {
  display: flex;
  width: 100%;
}

.table-header {
  padding: 12px 12px !important;
}

.table-body {
  padding: 6px 12px !important;
  display: flex;
}

.tabela-linha-hover:hover {
  background-color: #b4470a;
  transition: background-color ease-in-out 0.3s;
}

.tabela-linha-hover:hover > td > a {
  transition: color ease-in-out 0.3s;
  color: #fff;
}

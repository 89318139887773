.menu-principal-ativo > div {
  background-color: #217984 !important;
  color: rgba(255, 255, 255, 1) !important;
}

.menu-principal > div {
  color: rgba(255, 255, 255, 0.6);
}

.sub-menu-aberto > div {
  color: rgba(255, 255, 255, 1) !important;
}

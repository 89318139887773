.text-withe {
  color: #fff;
}

// margins
@for $i from 0 through 10 {
  $size: $i / 4;
  .m-#{$i} {
    margin: #{$size}rem !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$size}rem !important;
  }
  .mt-#{$i} {
    margin-top: #{$size}rem !important;
  }
  .ml-#{$i} {
    margin-left: #{$size}rem !important;
  }
  .mr-#{$i} {
    margin-right: #{$size}rem !important;
  }
}

// paddings
@for $i from 0 through 6 {
  $size: $i / 4;

  .p-#{$i} {
    padding: #{$size}rem !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$size}rem !important;
  }
  .pt-#{$i} {
    padding-top: #{$size}rem !important;
  }
  .pl-#{$i} {
    padding-left: #{$size}rem !important;
  }
  .pr-#{$i} {
    padding-right: #{$size}rem !important;
  }
}

// opacity
@for $i from 0 through 10 {
  $opacity: $i / 10;
  .opacity-#{$i} {
    opacity: #{$opacity} !important;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.h-100 {
  height: 100%;
  min-height: 100%;
}

// widths
@for $i from 1 through 10 {
  .width-#{$i*50} {
    width: #{$i * 50}px !important;
  }
}

.bg-white {
  background-color: white !important;
}

.line-height-1 {
  line-height: 1;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.btn-warning {
  background-color: rgb(255, 204, 0) !important;
}

.btn-warning:hover {
  background-color: rgb(218, 176, 8) !important;
}

.btn-success {
  background-color: rgb(107, 221, 14) !important;
}

.btn-success:hover {
  background-color: rgb(91, 185, 14) !important;
}

.btn-danger {
  background-color: rgb(211, 64, 64) !important;
}

.btn-danger:hover {
  background-color: rgb(163, 50, 50) !important;
}

.btn-dark {
  background-color: rgb(44, 44, 44) !important;
  color: #fff !important;
}

.btn-dark:hover {
  background-color: rgb(0, 0, 0) !important;
  color: #fff !important;
}

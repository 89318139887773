.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}

.d-inline {
  display: inline;
}
.j-around {
  justify-content: space-around;
}

.j-between {
  justify-content: space-between;
}

.j-center {
  justify-content: center;
}

.j-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal {
  max-width: 90%;
  max-height: 90%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__header {
  padding: 1rem;
  // background-color: $metalife-color;
  color: white;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}

.modal__header h1 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 2.2rem;
  flex-grow: 1;
}

.modal__actions {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.modal-sm {
  width: 30rem;
}
.modal-md {
  width: 50rem;
}
.modal-lg {
  width: 90%;
}

.modal-full {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}
.modal-full > .modal__content {
  overflow: auto;
  height: 100%;
}

.modal__content {
  position: absolute;
  top: 68px;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
}

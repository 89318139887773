#topo {
  background-color: #008591;
  display: flex;
  width: 100%;
  // position: fixed;
  top: 0;
  z-index: 999;

  .logo {
    padding: 6px;
    display: flex;
    flex-direction: column;
    width: 110px;
    img {
      display: block;
    }
    .nomeAplicacao {
      color: #fff;
      font-size: 0.6rem;
      text-align: right;
    }
  }
}
